<template>
  <div class="scene-wrap">
    <a-scene light="defaultLightsEnabled: true" stats>
      <!-- Lights  💡 -->
      <a-entity light="type: ambient; color: #BBB"></a-entity>
      <a-entity
        light="type: directional; color: #FFF; intensity: 0.6"
        position="-0.5 1 1"
      ></a-entity>

      <!-- Camera 📷 -->
      <!-- wasd-controls -->
      <a-entity id="rig" position="3 0 0" movement-controls>
        <a-entity
          camera
          look-controls
          position="0 2 0"
          raycaster="objects: .intractable"
          cursor="rayOrigin: mouse"
        ></a-entity>
      </a-entity>

      <a-sky
        src="textures/stars.webp"
        animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 200000"
      ></a-sky>


      <!-- <a-entity position="0 0 -4"  light="type: spot; angle: 45; color: #B1D3CD;"></a-entity> -->
      <a-entity class="world" position="0 0 0" rotation="0 90 0">
      <RobotArea />
        <a-entity class="pods">
          <Pod
            logo="vue"
            logoRotation="0 80 0"
            logoScale="0.15 0.45 0.45"
            offset="0"
          />
          <Pod
            logo="aframe"
            logoRotation="0 30 0"
            logoScale="0.03 0.03 0.03"
            offset="-0.2"
            position="3 0 0"
          />
          <Pod
            logo="node"
            logoRotation="0 -90 0"
            logoScale="0.13 0.13 0.13"
            position="6 0 0"
            offset="0"
          />
          <Pod
            logo="strapi"
            logoRotation="0 -90 0"
            logoScale="0.1 0.1 0.1"
            position="9 0 0"
            offset="0"
          />
          <Pod
            logo="blender"
            logoRotation="0 -20 0"
            logoScale="0.7 0.7 0.7"
            position="12 0 0"
            offset="-1"
          />
        </a-entity>
        <a-entity class="walls" position="0 0 0">
          <!-- door -->
          <a-gltf-model
            src="models/walls/double_door_wall.glb"
            position="-1.5 0 3.2"
            rotation="0 90 0"
          ></a-gltf-model>
          <!-- left walls -->
          <a-gltf-model
            src="models/walls/wall_pipes_h.glb"
            position="0 0 -1.5"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/wall_mini_windows.glb"
            position="4 0 -1.5"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/wall_pipes_h.glb"
            position="8 0 -1.5"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/wall_mini_windows.glb"
            position="12 0 -1.5"
          ></a-gltf-model>
          <!-- right walls -->
          <a-gltf-model
            src="models/walls/wall_empty.glb"
            position="0 0 6.4"
            rotation="0 180 0"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/window_wall.glb"
            position="4 0 6.4"
            rotation="0 180 0"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/wall_empty.glb"
            position="8 0 6.4"
            rotation="0 180 0"
          ></a-gltf-model>
          <a-gltf-model
            src="models/walls/wall_empty.glb"
            position="12 0 6.4"
            rotation="0 180 0"
          ></a-gltf-model>
        </a-entity>
      </a-entity>

      <!-- <Computer /> -->
    </a-scene>
  </div>
</template>

<script>
import Pod from "@/components/Pod.vue";
import RobotArea from "@/components/RobotArea.vue";
export default {
  name: "Scene",
  components: {
    Pod,
    RobotArea,
  },
  data: () => {
    return {};
  },
  mounted: function () {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
