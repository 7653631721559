<template>
  <a-entity position="0 0 0" class="intractable">
    <!-- floor -->
    <a-entity position="14.55 0 0">
      <Computer
        position="-0.7 0 3.2"
        @right="(anim = 'Walk'), (pos.z += 3), (rot = 0)"
        @left="(anim = 'Walk'), (pos.z -= 3), (rot = 180)"
        @up="(anim = 'Walk'), (pos.x += 3), (rot = 90)"
        @down="(anim = 'Walk'), (pos.x -= 3), (rot = -90)"
        @dance="anim = 'Dance'"
      />
      <a-gltf-model
        :animation-mixer="`clip: ${anim}`"
        src="models/props/robot.glb"
        scale="0.5 0.5 0.5"
        position="3 0.2 0"
        :rotation="`0 ${rot} 0`"
        :animation="`property: position; to: ${pos.x} 0 ${pos.z}; easing: linear`"
        @animationcomplete="anim = 'Idle'"
        @model-loaded="loaded"
      >
      </a-gltf-model>

      <a-entity v-for="y in 5" :key="y">
        <a-gltf-model
          src="models/floor/floor_empty.glb"
          v-for="x in 8"
          :key="x"
          :position="`${-2 + y * 2} 0 ${-5 + x * 2}`"
        ></a-gltf-model>
      </a-entity>

      <!-- walls -->
      <a-gltf-model
        v-for="x in 4" :key="x"
        src="models/walls/window_wall.glb"
        :position="`8 0 ${-5.7 + x * 4}`"
        rotation="0 -90 0"
      ></a-gltf-model>
      <a-gltf-model
        v-for="x in 2" :key="x"
        src="models/walls/window_wall.glb"
        :position="`${-1.7 + x * 4} 0 -3.4`"
        rotation="0 0 0"
      ></a-gltf-model>
      <a-gltf-model
        v-for="x in 2" :key="x"
        src="models/walls/window_wall.glb"
        :position="`${-1.7 + x * 4} 0 12`"
        rotation="0 180 0"
      ></a-gltf-model>
    </a-entity>
  </a-entity>
</template>

<script>
import Computer from "@/components/Computer.vue";

export default {
  name: "RobotArea",
  components: {
    Computer,
  },
  data: function () {
    return { hover: false, pos: { x: 5, y: 0, z: 3 }, rot: -90, anim: "Idle" };
  },
  mounted: function () {},
  methods: {
    loaded: function (e, color) {
      console.log(e.target.object3D);
    },
    robotRight: function (e, color) {
      console.log(e.target.object3D);
    },
  },
};
// 0: _r {name: "Dance", tracks: Array(51), duration: 1, blendMode: 2500, uuid: "13CD1DB4-AB48-4D1A-8CAA-E4C8E17E3DA2"}
// 1: _r {name: "Death", tracks: Array(51), duration: 1.7916666269302368, blendMode: 2500, uuid: "00590AEA-09B5-47CD-A1AC-F937D8A6BC96"}
// 2: _r {name: "Hello", tracks: Array(51), duration: 1.875, blendMode: 2500, uuid: "1F4014B1-DC65-4CC9-8F6B-4CF0422DC698"}
// 3: _r {name: "HitRecieve_1", tracks: Array(51), duration: 0.5833333134651184, blendMode: 2500, uuid: "7276B051-82ED-4708-9790-0E12CE94A97D"}
// 4: _r {name: "HitRecieve_2", tracks: Array(51), duration: 0.5833333134651184, blendMode: 2500, uuid: "58276CC3-0669-4E3D-92A1-FF5789468C2E"}
// 5: _r {name: "Idle", tracks: Array(51), duration: 4.166666507720947, blendMode: 2500, uuid: "58E25E55-C90C-4E1F-A289-82FB6605F857"}
// 6: _r {name: "Jump", tracks: Array(51), duration: 1.0833333730697632, blendMode: 2500, uuid: "9EE1F263-CF52-4D53-B342-2C0A6B61FFEF"}
// 7: _r {name: "Kick", tracks: Array(51), duration: 0.75, blendMode: 2500, uuid: "7EBBA35A-D8AA-44D9-B845-611F717EE8FF"}
// 8: _r {name: "No", tracks: Array(51), duration: 1.75, blendMode: 2500, uuid: "516F5348-86CA-4DF4-B25F-456579F811AC"}
// 9: _r {name: "Pickup", tracks: Array(51), duration: 1.75, blendMode: 2500, uuid: "BA2F7714-AB1A-48ED-B700-D5C52CC7EBBE"}
// 10: _r {name: "Punch", tracks: Array(51), duration: 0.7083333134651184, blendMode: 2500, uuid: "327BD8E2-995A-4010-873A-10F69747262D"}
// 11: _r {name: "Run", tracks: Array(51), duration: 1.0416666269302368, blendMode: 2500, uuid: "16F2F9E5-1ED4-40EC-83A8-A56B19F2E48A"}
// 12: _r {name: "Run_Tall", tracks: Array(51), duration: 1.0416666269302368, blendMode: 2500, uuid: "18E487ED-3838-4B7B-B161-BD6E028A3114"}
// 13: _r {name: "Shoot", tracks: Array(51), duration: 0.625, blendMode: 2500, uuid: "71E47342-D442-4C3C-B5CD-C684400CF798"}
// 14: _r {name: "SwordSlash", tracks: Array(51), duration: 0.75, blendMode: 2500, uuid: "B2C754C5-7CAE-4C63-B156-1BFFFA814261"}
// 15: _r {name: "Walk", tracks: Array(51), duration: 1.0416666269302368, blendMode: 2500, uuid: "26CCDF8D-7C8D-4F52-B352-09C6756CEFAE"}
// 16: _r {name: "Walk_Tall", tracks: Array(51), duration: 1.0416666269302368, blendMode: 2500, uuid: "BAA55EB5-E548-4F69-AEDC-A4CBD268A7CB"}
// 17: _r {name: "Yes", tracks: Array(51), duration: 1.75, blendMode: 2500, uuid: "58D8492C-2AA4-49F2-96B1-5E3541F54566"}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>