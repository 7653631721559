<template>
  <a-entity class="intractable" @mouseenter="toggle" @mouseleave="toggle">
    <a-gltf-model
      class="intractable"
      src="models/props/pod.glb"
      @model-loaded="pod"
    >
    </a-gltf-model>

    <a-entity
      class="logo"
      position="0 1.2 0"
      :animation="`property: position; to: 0 ${
        1.5 + Math.random()
      } 0; loop: true; dir: alternate; dur: 2000;`"
    >
      <a-gltf-model
        class="logo"
        :src="`models/logos/${logo}_logo.glb`"
        :position="`0 ${offset} 0`"
        :rotation="logoRotation"
        :scale="logoScale"
      ></a-gltf-model>
      <a-entity
        class="intractable"
        :light="`type: point; intensity: ${
          hover ? 3 : 1
        }; distance:10; decay: 5; color: ${hover ? '#B1D3CD' : 'green'};`"
      ></a-entity>
    </a-entity>

    <TileCombo />
  </a-entity>
</template>

<script>
import TileCombo from "@/components/misc/TileCombo.vue";
export default {
  name: "Pod",
  props: ["logo", "logoScale", "logoRotation", "offset"],
  data: () => {
    return { hover: false };
  },
  mounted: function () {},
  methods: {
    pod: function (e, color) {
      let material = e.target.object3D.children[0].children[0].children[1];
    },
    toggle: function (e) {
      this.hover = !this.hover;
    },
  },
  components: {
    TileCombo,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
