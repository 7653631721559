<template>
  <a-entity class="tiles" position="0 0 0" scale="0.8 0.8 0.8">
    <!-- under-pod tiles -->
    <a-gltf-model
      src="models/floor/tile_pod.glb"
      :position="`-1 0 -1`"
    ></a-gltf-model>
    <a-gltf-model
      src="models/floor/tile_pod.glb"
      :position="`1 0 -1`"
    ></a-gltf-model>
    <a-gltf-model
      src="models/floor/tile_pod.glb"
      :position="`-1 0 1`"
    ></a-gltf-model>
    <a-gltf-model
      src="models/floor/tile_pod.glb"
      :position="`1 0 1`"
    ></a-gltf-model>
    <!-- hall tiles -->
    <a-gltf-model
      src="models/floor/tile_line.glb"
      rotation="0 90 0"
      :position="`-1 0 5`"
    ></a-gltf-model>
    <a-gltf-model
      src="models/floor/tile_line.glb"
      rotation="0 90 0"
      :position="`1 0 5`"
    ></a-gltf-model>
    <!-- right tile -->
    <a-gltf-model
      src="models/floor/floor_right.glb"
      rotation="0 0 0"
      :position="`-1 0 7`"
    ></a-gltf-model>
    <a-gltf-model
      src="models/floor/floor_right.glb"
      rotation="0 0 0"
      :position="`1 0 7`"
    ></a-gltf-model>

  </a-entity>
</template>

<script>
export default {
  name: "TilesCombo",
  data: () => {
    return {};
  },
  mounted: function () {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
