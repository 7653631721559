<template>
  <div class="Main">
    <!-- <div class="overlay"><h1>Web ❤️ 3D</h1></div> -->
    <Scene />
  </div>
</template>

<script>
import Scene from "@/components/Scene.vue";
export default {
  name: "Main",
  components: {
    Scene,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.overlay {
  margin-top: 20px;
  color: white;
  text-shadow: 1px 1px 2px black;
  font-size: 2rem;
}
</style>
