<template>
  <a-entity position="0 0 0" class="intractable">
    <div class="debug">{{ btnsHover.right }}</div>
    <a-gltf-model
      src="models/props/computer.glb"
      rotation="0 -90 0"
      ><a-plane
        scale="0.7 0.55 0.7"
        rotation="-26 0 0"
        position="0 1.26 0.18"
        color="#767676"
        class="img"
      >
        <a-box
          class="intractable right"
          scale="0.2 0.2 0.2"
          position="0.3 0 0"
          @click="$emit('right')"
          @mouseenter="btnsHover.right = true"
          @mouseleave="btnsHover.right = false"
          :color="`${btnsHover.right ? '#ef2d5e' : '#b70000'}`"
          ><a-image src="textures/arrow.svg" position="0 0 0.55"></a-image
        ></a-box>
        <a-box
          class="intractable left"
          scale="0.2 0.2 0.2"
          position="-0.3 0 0"
          rotation="0 0 180"
          @click="$emit('left')"
          @mouseenter="btnsHover.left = true"
          @mouseleave="btnsHover.left = false"
          :color="`${btnsHover.left ? '#ef2d5e' : '#b70000'}`"
          ><a-image src="textures/arrow.svg" position="0 0 0.55"></a-image
        ></a-box>
        <a-box
          class="intractable up"
          scale="0.2 0.2 0.2"
          position="0 0.3 0"
          rotation="0 0 90"
          @click="$emit('up')"
          @mouseenter="btnsHover.up = true"
          @mouseleave="btnsHover.up = false"
          :color="`${btnsHover.up ? '#ef2d5e' : '#b70000'}`"
          ><a-image src="textures/arrow.svg" position="0 0 0.55"></a-image
        ></a-box>
        <a-box
          class="intractable down"
          scale="0.2 0.2 0.2"
          position="0 -0.3 0"
          rotation="0 0 -90"
          @click="$emit('down')"
          @mouseenter="btnsHover.down = true"
          @mouseleave="btnsHover.down = false"
          :color="`${btnsHover.down ? '#ef2d5e' : '#b70000'}`"
          ><a-image src="textures/arrow.svg" position="0 0 0.55"></a-image
        ></a-box>

        <!-- dance -->
        <a-box
          class="intractable dance"
          scale="0.2 0.2 0.2"
          position="0.4 0.4 0"
          rotation="0 0 90"
          @click="$emit('dance')"
          ><a-image src="textures/arrow.svg" position="0 0 0.55"></a-image
        ></a-box>
      </a-plane>
      <!-- #ef2d5e -->
      <!-- <a-entity position="0 1.66408 1.62713" light="type: spot; angle: 45"></a-entity> -->
    </a-gltf-model>
  </a-entity>
</template>

<script>
export default {
  name: "Computer",
  data: function () {
    return {
      btnsHover: { up: false, down: false, left: false, right: false },
    };
  },
  methods: {
    enter: function (e, color) {
      e.target.object3D.children[1].children[1].children[0].material.color =
        new THREE.Color("#ffff5e");
    },
    originAccentColor: function (e, color) {
      e.target.object3D.children[1].children[1].children[0].material.color =
        new THREE.Color("#C59449");
    },
    hoverBtn: function (e) {
      this.btnsHover.right = true;
      console.log('fa');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.debug {
  position: absolute;
  z-index: 43;
}
</style>
